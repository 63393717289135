<template>
  <div>
    <div class="reservation-container">
      <div class="reservation-img">
        <img src="../image/pc.jpg" v-if="$store.state.pageset.pcshow" />
        <img src="../image/pc.jpg" v-else />
      </div>
      <div class="reservation-container-form">
        <p class="p1">Welcome to Sevnce Robotics</p>
        <p class="p2" v-animate="'queue-bottom'">Please leave your relevant information</p>
        <p class="p3" v-animate="'queue-bottom'">We will contact you promptly</p>
        <el-form ref="form" :rules="formRules" :model="form">
          <el-form-item prop="name" v-animate="'queue-bottom'">
            <el-input v-model="form.name" placeholder="Your Name"></el-input>
          </el-form-item>
          <el-form-item v-animate="'queue-bottom'"  prop="phone">
            <el-input v-model="form.phone" placeholder="Phone Number"></el-input>
          </el-form-item>
          <el-form-item prop="corporateName" v-animate="'queue-bottom'">
            <el-input v-model="form.corporateName" placeholder="Company Name"></el-input>
          </el-form-item>
          <el-form-item prop="area" v-animate="'queue-bottom'">
            <el-input v-model="form.area" placeholder="Province/City/District"></el-input>
            <!-- <el-cascader :props="props"></el-cascader> -->
          </el-form-item>
          <el-form-item v-animate="'queue-bottom'" prop="email" >
            <el-input v-model="form.email" placeholder="Your Email"></el-input>
          </el-form-item>
          <el-form-item prop="remark" v-animate="'queue-bottom'">
            <el-input
              type="textarea"
              v-model="form.remark"
              placeholder="Information Description"
              class="textarea-div"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          size="small"
          class="btn-div-experience black-btn"
          plain
          @click="orderNow"
          :loading="orderLoading"
          >Submit</el-button
        >
        <div class="p4">
          <p>National Free Service Hotline:400-8765-700</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import index from "./index.js";

export default index;
</script>
<style scoped lang="scss">
@import "index.scss";
@import "@/assets/style/common.scss";
</style>
